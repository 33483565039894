import React, { useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ProductHighlights.module.css";
import products from "../../../data/products";

const ProductHighlights: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | HTMLAnchorElement | null)[]>([]);
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt";

  useEffect(() => {
    let observer: IntersectionObserver;

    const initializeObserver = () => {
      if (carouselRef.current && itemRefs.current.length > 0) {
        observer = new IntersectionObserver(
          (entries, observerInstance) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                (entry.target as HTMLDivElement).classList.add(styles.visible);
                observerInstance.unobserve(entry.target);
              }
            });
          },
          {
            root: carouselRef.current,
            threshold: 0.1,
          }
        );

        itemRefs.current.forEach((item) => {
          if (item) observer.observe(item);
        });
      }
    };

    requestAnimationFrame(initializeObserver);

    return () => {
      if (observer) observer.disconnect();
    };
  }, []);

  return (
    <section className={styles.productHighlights}>
      <h2 className={styles.productTitle}>{t("product_highlights.title")}</h2>
      <div className={styles.productGrid} ref={carouselRef}>
        {products.map((product, index) => (
          <Link
            to={`/${language}${t("routes.product")}/${product.id}`}
            key={product.id}
            className={styles.productItem}
            ref={(el) => (itemRefs.current[index] = el)}
          >
            <img
              src={product.img}
              alt={t(`products.${product.id}.name`)}
              className={styles.productImage}
              loading={index < 3 ? "eager" : "lazy"}
            />
            <h3 className={styles.productName}>
              {t(`products.${product.id}.name`)}
            </h3>
            <p className={styles.productPrice}>
              {t(`products.${product.id}.price`)}
            </p>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ProductHighlights;
