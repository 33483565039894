// src/data/products.ts
import Product1 from "../assets/images/products/AquaJet.png";
import Product2 from "../assets/images/products/AquaMop.png";
import Product3 from "../assets/images/products/AquaSet.png";
import Product4 from "../assets/images/products/MiniPowerbrush.png";
import Product5 from "../assets/images/products/CarpetShampoo.png";
import Product6 from "../assets/images/products/Deodorizer.png";
import Product7 from "../assets/images/products/Fragrancias.png";
import Product8 from "../assets/images/products/SprayGun.png";
import Product9 from "../assets/images/products/TubosInox.jpg";
import Product10 from "../assets/images/products/EscovaChao.jpg";
import Product11 from "../assets/images/products/EscovaPo.jpg";
import Product12 from "../assets/images/products/EscovaTecidos.jpg";
import Product13 from "../assets/images/products/FerramentaInsuflavel.jpg";

interface Product {
  id: string;
  img: string;
}

const products: Product[] = [
  { id: "fragrances", img: Product7 },
  { id: "deodorizer", img: Product6 },
  { id: "carpet-shampoo", img: Product5 },
  { id: "tubos-inox", img: Product9 },
  { id: "escova-chao", img: Product10 },
  { id: "escova-po", img: Product11 },
  { id: "escova-tecidos", img: Product12 },
  { id: "ferramenta-insuflavel", img: Product13 },
  { id: "aquajet", img: Product1 },
  { id: "aquaset", img: Product3 },
  { id: "mini", img: Product4 },
  { id: "aquamop", img: Product2 },
  { id: "spray-gun", img: Product8 },
  // Add more products as needed
];

export default products;
