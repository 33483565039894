import React from "react";
import styles from "./PrivacyPolicyContent.module.css";

const PrivacyPolicyContent: React.FC = () => {
  return (
    <div className={styles.privacyPolicyContainer}>
      <h1>A Nossa Politica de Privacidade</h1>
      <h3>1 - Introdução</h3>
      <p>
        Estamos empenhados no respeito pela privacidade e confidencialidade dos
        dados pessoais que lhe são disponibilizados e por isso nesta Política de
        Privacidade encontra informação sobre a forma como os mesmos são
        tratados em conformidade com o Regulamento Geral de Proteção de Dados.
        Adoptamos medidas administrativas e tecnológicas para cumprir com o novo
        Regulamento Geral de Proteção de Dados. A utilização deste website
        rege-se pelos termos e condições a seguir indicados e que o utilizador
        se obriga a respeitar na íntegra. O utilizador não deverá utilizar este
        website caso não concorde com estes termos e condições.
      </p>
      <h3>2 - O seu consentimento</h3>
      <p>
        Ao utilizar o nosso website e as nossas plataformas, concorda com nossa
        Política de Privacidade.
      </p>
      <h3>3 - Porque tratamos os dados pessoais?</h3>
      <p>
        Os dados pessoais recolhidos destinam-se, fundamentalmente, à resposta a
        pedidos de informação e contacto, à gestão contratual, à prestação dos
        serviços e à comunicação de alterações aos produtos e serviços
        subscritos, podendo, contudo, com o seu consentimento, ser também usados
        na divulgação de novos produtos e serviços e de notícias sobre a
        empresa, na realização de inquéritos de avaliação e em ações de
        informação legalmente obrigatórias.
      </p>
      <h3>4 - Que dados pessoais são recolhidos?</h3>
      <p>
        Normalmente, os dados pessoais recolhidos limitam-se ao Nome, Morada,
        Número de Telefone / Telemóvel, Número de Contribuinte, Endereço de
        E-mail, mas poderão ser recolhidos outros dados necessários ao
        fornecimento de produtos ou serviços. Adicionalmente, quando são
        realizados envios de emails são recolhidos dados estatísticos que
        permitem conhecer o comportamento de cada subscritor face ao email
        recebido.
      </p>
      <h3>5 - Como são recolhidos os dados pessoais?</h3>
      <p>
        Em geral, os dados pessoais são recolhidos aquando da subscrição de um
        dos nossos produtos ou serviços, sendo que alguns dos dados pessoais são
        de fornecimento obrigatório para a prestação do serviço. Também
        recolhemos os seus dados quando subscreve o envio de newsletters, para
        responder às suas mensagens e pedidos de informação, para analisar e
        formalizar a sua candidatura a parceiro ou a colaborador, para as
        operações e gestão do website, para prevenção de fraudes e para manter
        um registo dos seus detalhes de contacto. Respeitamos sempre o seu
        direito à privacidade e não recolhemos qualquer informação pessoal sobre
        si sem o seu consentimento prévio e explícito. Os seus dados pessoais
        não serão reutilizados para outras finalidades que sejam previamente
        identificadas ou que não tenham qualquer relação com aquelas finalidades
        para as quais foram inicialmente recolhidos. Todavia a disponibilização
        de dados incorretos ou inexatos é da sua exclusiva responsabilidade.
      </p>
      <h3>6 - Dados de menores</h3>
      <p>
        O website não é dirigido a menores de 16 anos, pelo que solicitamos que
        estes menores não utilizem o website e não nos forneçam dados pessoais
        através do mesmo.
      </p>
      <h3>7 - Dados sensíveis</h3>
      <p>
        Solicitamos de forma expressa, que não nos envie e não nos dê a conhecer
        quaisquer dados pessoais sensíveis, ou seja, informações que revelem a
        origem racial ou étnica, opiniões políticas, crenças religiosas ou
        filosóficas, associação sindical, informações genéticas, informações
        biométricas, dados relativos à saúde ou dados relativos a vida sexual de
        uma pessoa natural ou a orientação sexual. Esses dados pessoais serão
        imediatamente apagados.
      </p>
      <h3>8 - Qual o período de conservação de dados pessoais?</h3>
      <p>
        Cabe-nos referir que os dados pessoais que nos fornece serão conservados
        até um período máximo de dez anos. No entanto, em determinados casos
        poderemos conservar os dados durante períodos mais longos, nomeadamente
        quando a Lei assim o impuser.
      </p>
      <h3>9 - Direito de acesso, rectificação e actualização de dados</h3>
      <p>
        Nos termos da legislação aplicável, é-lhe garantido, na qualidade de
        titular dos dados, o direito, a qualquer momento, de aceder aos seus
        dados pessoais, solicitar a sua retificação ou aditamento, opor-se ao
        tratamento dos dados, com exceção dos estritamente necessários à
        prestação do serviço e/ou solicitar a portabilidade dos dados, requerer
        a eliminação dos seus dados para fins de marketing ou telemarketing ou
        inclusão em listas de assinantes e serviços informativos. Poderá
        requerer qualquer uma das ações acima mencionadas através da nossa
        página de contactos presente no website, utilizando o meio mais
        conveniente e acessível às suas necessidades, estabelecendo comunicação
        connosco através da nossa morada, telefone ou e-mail presentes no link:
        Contactos Caso nos solicite a eliminação de alguns dos seus dados
        pessoais ou da sua totalidade, alguns dos serviços solicitados poderão
        não lhe ser prestados, conservando os dados pessoais necessários para o
        cumprimento das suas obrigações legais. Reservamos o direito de
        solicitar acesso a elementos identificativos do autor do pedido em ordem
        a comprovar a respectiva identidade.
      </p>
      <h3>10 - Divulgação de informação a terceiros</h3>
      <p>
        Não vendemos ou comercializamos os seus dados. No âmbito da sua
        atividade, poderemos recorrer a terceiros para a prestação de
        determinados serviços, o que poderá implicar o acesso, por estas
        entidades, a dados pessoais dos nossos utilizadores e clientes. Quando
        tal sucede, tomamos todas as precauções adequadas, impondo
        contratualmente a estas entidades que ponham em prática as medidas
        técnicas e organizacionais adequadas para obstar a qualquer tratamento
        ilícito dos dados e para assegurar a sua disponibilidade, integridade e
        confidencialidade. Os dados pessoais também poderão ser disponibilizados
        aos tribunais e às demais autoridades competentes, no estrito
        cumprimento do disposto na lei, designadamente quando se revelem
        necessários para a resolução de litígios relativos à faturação e para
        atividades relativas à proteção da segurança pública, da defesa e
        segurança do Estado e à prevenção, investigação ou detecção de ilícitos
        criminais.
      </p>
      <h3>11 - Segurança</h3>
      <p>
        Assegurar a confidencialidade, integridade e disponibilidade dos dados é
        um compromisso que assumimos para com todos os nossos clientes e
        utilizadores. Nesse sentido seguimos as melhores práticas de gestão de
        segurança da informação, exigindo aos nossos colaboradores o cumprimento
        estrito das rigorosas normas, asseguramento da segurança dos dados
        pessoais que nos sejam comunicados, não podendo utilizar tais dados para
        quaisquer outras finalidades que não as definidas no contrato, nem
        correlacioná-los com outros dados que se encontrem na sua
        disponibilidade. Alertamos para o facto de os dados que circulam na
        Internet não estarem totalmente protegidos contra desvios eventuais,
        sendo a comunicação de senhas de acesso, passwords, códigos
        confidenciais e de qualquer informação sensível efetuada sob a sua
        inteira responsabilidade.
      </p>
      <h3>12 - Cookies</h3>
      <p>
        Utilizamos cookies neste website. Um cookie é um pequeno arquivo de
        texto que identifica o seu computador no nosso servidor. Os cookies em
        si não identificam o utilizador individual, apenas o computador
        utilizado. Os cookies não são usados para recolher informações pessoais.
        Este website utiliza cookies que servem para ajudar a determinar a
        utilidade, interesse e o número de acessos ao website, permitindo uma
        navegação mais rápida e eficiente do mesmo, eliminando a necessidade de
        introduzir repetidamente as mesmas informações. A utilização de cookies
        por este sítio pressuporá sempre o seu prévio consentimento. Os cookies
        que utilizamos: PHPSESSID: cookie que permite ao website responder a
        qualquer ação que o Utilizador executa no website, tal como completar um
        formulário de inquérito. O website não funciona corretamente se este
        cookie não for usado. xms_user: cookie usado por utilizadores de
        backoffice. O website funciona corretamente se este cookie não for
        usado. __utma, __utmb, __utmc, __utmz: Cookies __ utmz começando "__utm"
        permitem a função do software de análise (analytics). Este software
        ajuda a analisar os visitantes do website e fornecer informações
        anónimas, como navegadores utilizados, visitantes de retorno e de
        resposta às atividades de marketing. __atuvc: O cookie _atuvc é criado e
        lido pelo JavaScript do website que permite a partilha de conteúdos em
        redes sociais "AddThis". Todos os browsers permitem ao Utilizador
        aceitar, recusar ou apagar cookies, nomeadamente através da seleção das
        definições apropriadas no respetivo navegador. Pode configurar os
        cookies no menu "configurações" ou "preferências" do seu browser. Para
        saber mais sobre os cookies, incluindo a forma de ver o que os cookies
        foram criados e como gerenciar e excluí-los, visite
        www.allaboutcookies.org que inclui informações sobre como gerir as suas
        configurações para os vários fornecedores de navegadores. Note-se, no
        entanto, que, ao desativar cookies, pode afetar, parcial ou totalmente,
        a sua experiência de navegação no website. Google Analytics navegador
        opt-out Para disponibilizar aos visitantes uma maior escolha sobre como
        os seus dados são recolhidos pelo Google Analytics, o Google desenvolveu
        o Google Analytics Navegador Opt-out Add-on. O add-on comunica com o
        JavaScript do Google Analytics (ga.js) para indicar que as informações
        sobre a visita do sítio não devem ser enviadas para o Google Analytics.
        O Google Analytics Navegador Opt-out extra não impede que as informações
        sejam enviadas para o sítio em si ou para outros serviços da web
        analytics. Saiba mais sobre o Google Analytics Brower Opt-out extra.
      </p>
      <h3>13 - Links para websites terceiros</h3>
      <p>
        Os links constantes deste website podem conduzir a outros websites. Não
        nos responsabilizamos, aprovamos ou por qualquer forma apoiamos ou
        subscrevemos o conteúdo desses websites, nem dos websites com eles
        ligados ou neles referidos.
      </p>
      <h3>14 - Alterações à política de privacidade</h3>
      <p>
        Reservamo-nos o direito de modificar esta política de privacidade a
        qualquer momento, pelo que aconselhamos a sua consulta com regularidade.
        Todas as mudanças entrarão em vigor imediatamente após sua publicação no
        website. Se se opõe às mudanças na nossa política de privacidade, deverá
        deixar de utilizar os nossos serviços, inclusive o website.
      </p>
      <h3>15 - Disposições gerais</h3>
      <p>
        Não nos responsabilizamos por danos resultantes de vírus que possam
        infectar o computador ou a rede do utilizador, ou outros bens, em
        virtude do acesso ao nosso website com transferência de conteúdos deste
        para o computador ou rede do utilizador.
      </p>
      <h3>16 - Contactos, dúvidas e questões</h3>
      <p>
        Se tiver alguma dúvida ou questão, ou se deseja atualizar, excluir ou
        alterar qualquer informação pessoal que possuamos relacionada consigo,
        ou se tem uma preocupação sobre a maneira como lidamos com qualquer
        questão de privacidade, através da nossa página de contactos presente no
        website, utilizando o meio mais conveniente e acessível às suas
        necessidades, estabelecendo comunicação connosco através da nossa
        morada, telefone ou e-mail presentes no link: Contactos
      </p>
    </div>
  );
};

export default PrivacyPolicyContent;
