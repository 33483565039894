// src/components/ProductComponents/ProductDetail/ProductDetail.tsx
import React from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ProductDetail.module.css";
import Nav from "../../Nav/Nav";
import Footer from "../../Footer/Footer";
import products from "../../../data/products";
import PaymentMethods from "../../PaymentMethods/PaymentMethods";

const ProductDetail: React.FC = () => {
  const { productId, lng } = useParams<{ productId: string; lng: string }>();
  const { t } = useTranslation();
  const language = lng || "pt"; // Default to 'pt' if undefined

  const product = products.find((p) => p.id === productId);

  if (!product) {
    return (
      <>
        <Nav />
        <div className={styles.productDetailNotFound}>
          <h2>{t("product_detail.not_found")}</h2>
          <Link
            to={`/${language}/products`}
            className={styles.productDetailBackLink}
          >
            &larr; {t("product_detail.back_to_products")}
          </Link>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Nav />
      <div className={styles.productDetail}>
        <div className={styles.productDetailContainer}>
          <img
            src={product.img}
            alt={t(`products.${product.id}.name`)}
            className={styles.productDetailImage}
          />
          <div className={styles.productDetailInfo}>
            <h1 className={styles.productDetailName}>
              {t(`products.${product.id}.name`)}
            </h1>
            <p className={styles.productDetailPrice}>
              {t(`products.${product.id}.price`)}
            </p>
            <div className={styles.productDetailDivider}></div>
            <p className={styles.productDetailDescription}>
              {t(`products.${product.id}.description`)}
            </p>
            <p className={styles.productDetailSpecialDescription}>
              {t("product_detail.development_notice")}
            </p>
            {/* Changed the button to an anchor with tel link */}
            <a href="tel:+351210939772" className={styles.buyButton}>
              {t("product_detail.call_now")}
            </a>
            <p>{t("product_detail.call_cost_notice")}</p>
            <Link
              to={`/${language}/products`}
              className={styles.productDetailBackLink}
            >
              &larr; {t("product_detail.back_to_products")}
            </Link>
          </div>
        </div>
        {/* Include the PaymentMethods section below the product details */}
        <PaymentMethods />
      </div>
      <Footer />
    </>
  );
};

export default ProductDetail;
