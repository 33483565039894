// components/CompanyInfo/CompanyInfo.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CompanyInfo.module.css";
import {
  FaMapMarkerAlt,
  FaClock,
  FaEnvelope,
  FaPhone,
  FaHome,
} from "react-icons/fa";
import locations from "../../data/locations";

const CompanyInfo: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.companyInfoSection}>
      <h2>{t("company_info.title")}</h2>
      <div className={styles.infoWrapper}>
        {/* Loop through the locations and display each one */}
        {locations.map((location) => (
          <div className={styles.infoItem} key={location.id}>
            <h4>
              <FaMapMarkerAlt /> {location.name}
            </h4>
            <p className={styles.locationAddress}>
              <FaHome /> {location.address}
            </p>
            <p>
              <FaClock /> {location.description}
            </p>
            <p>
              <FaEnvelope /> {location.email}
            </p>
            <p>
              <FaPhone /> {location.phone}
            </p>
            <p>{t("company_info.call_cost_notice")}</p>
            <div className={styles.mapContainer}>
              <iframe
                src={location.mapSrc}
                width="100%"
                height="250"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                title={`map-${location.id}`}
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CompanyInfo;
