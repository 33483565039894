import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./ProductGrid.module.css";
import products from "../../../data/products";

const ProductGrid: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt"; // Default to 'pt' if undefined

  return (
    <section className={styles.productGridSection}>
      <div className={styles.productGrid}>
        {products.map((product) => (
          <Link
            to={`/${language}${t("routes.product")}/${product.id}`}
            key={product.id}
            className={styles.productItemLink}
          >
            <div className={styles.productItem}>
              <img
                src={product.img}
                alt={t(`products.${product.id}.name`)}
                className={styles.productImage}
              />
              <h3 className={styles.productName}>
                {t(`products.${product.id}.name`)}
              </h3>
              <p className={styles.productPrice}>
                {t(`products.${product.id}.price`)}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ProductGrid;
