// src/pages/RecruitmentPage/RecruitmentSection.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./RecruitmentSection.module.css";

const RecruitmentSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.recruitmentContainer}>
      <h2 className={styles.heading}>{t("recruitment_section.heading")}</h2>
      <p className={styles.description}>
        {t("recruitment_section.description")}
      </p>

      <div className={styles.section}>
        <h3 className={styles.subheading}>
          {t("recruitment_section.benefits.title")}
        </h3>
        <ul className={styles.list}>
          <li>{t("recruitment_section.benefits.item1")}</li>
          <li>{t("recruitment_section.benefits.item2")}</li>
          <li>{t("recruitment_section.benefits.item3")}</li>
          <li>{t("recruitment_section.benefits.item4")}</li>
          <li>{t("recruitment_section.benefits.item5")}</li>
        </ul>
      </div>

      <div className={styles.section}>
        <h3 className={styles.subheading}>
          {t("recruitment_section.how_it_works.title")}
        </h3>
        <p className={styles.description}>
          {t("recruitment_section.how_it_works.description")}
        </p>
      </div>
    </section>
  );
};

export default RecruitmentSection;
