// src/components/Testimonials/TestimonialsSection.tsx
import React from "react";
import styles from "./Testimonials.module.css";

interface Testimonial {
  name: string;
  quote: string;
  avatar?: string;
}

interface TestimonialsSectionProps {
  testimonials: Testimonial[];
  title: string;
}

// Helper function to get initials from the name
const getInitials = (name: string) => {
  return name
    .split(" ")
    .map((word) => word[0])
    .join("");
};

const TestimonialsSection: React.FC<TestimonialsSectionProps> = ({
  testimonials,
  title,
}) => {
  return (
    <section className={styles.testimonialsSection}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.testimonialsWrapper}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className={styles.testimonialItem}>
            {testimonial.avatar ? (
              <img
                src={testimonial.avatar}
                alt={`${testimonial.name} avatar`}
              />
            ) : (
              <div className={styles.initialsAvatar}>
                {getInitials(testimonial.name)}
              </div>
            )}
            <p>"{testimonial.quote}"</p>
            <h4>{testimonial.name}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsSection;
