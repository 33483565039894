// src/pages/CleaningSuggestionsPage/CleaningSuggestionsSection.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import CleaningSuggestion1 from "../../assets/images/cleaning-suggestions/cleaning-suggestions1.jpg";
import CleaningSuggestion2 from "../../assets/images/cleaning-suggestions/cleaning-suggestions2.jpg";
import CleaningSuggestion4 from "../../assets/images/cleaning-suggestions/cleaning-suggestions4.png";
import CleaningSuggestion5 from "../../assets/images/cleaning-suggestions/cleaning-suggestions5.png";
import CleaningSuggestion7 from "../../assets/images/cleaning-suggestions/cleaning-suggestions7.png";
import styles from "./CleaningSuggestionsSection.module.css";
import Timeline from "../../components/HistoryComponents/Timeline/Timeline";
import Milestone from "../../components/HistoryComponents/Milestone/Milestone";

const CleaningSuggestionsSection: React.FC = () => {
  const { t } = useTranslation();

  const milestones = [
    {
      title: t("cleaning_suggestions.milestone1.title"),
      description: t("cleaning_suggestions.milestone1.description"),
      image: CleaningSuggestion1,
      isRightAligned: false,
    },
    {
      title: t("cleaning_suggestions.milestone2.title"),
      description: t("cleaning_suggestions.milestone2.description"),
      image: CleaningSuggestion2,
      isRightAligned: true,
    },
    {
      title: t("cleaning_suggestions.milestone3.title"),
      description: t("cleaning_suggestions.milestone3.description"),
      image: CleaningSuggestion4,
      isRightAligned: false,
    },
    {
      title: t("cleaning_suggestions.milestone4.title"),
      description: t("cleaning_suggestions.milestone4.description"),
      image: CleaningSuggestion5,
      isRightAligned: true,
    },
    {
      title: t("cleaning_suggestions.milestone5.title"),
      description: t("cleaning_suggestions.milestone5.description"),
      image: CleaningSuggestion7,
      isRightAligned: false,
    },
    {
      title: t("cleaning_suggestions.milestone6.title"),
      description: t("cleaning_suggestions.milestone6.description"),
      videoUrl: "https://www.youtube.com/embed/H04tpkLbviU",
      isRightAligned: true,
    },
    {
      title: t("cleaning_suggestions.milestone7.title"),
      description: t("cleaning_suggestions.milestone7.description"),
      videoUrl: "https://www.youtube.com/embed/Kd9gbHMN7qs",
      isRightAligned: false,
    },
    {
      title: t("cleaning_suggestions.milestone8.title"),
      description: t("cleaning_suggestions.milestone8.description"),
      videoUrl: "https://www.youtube.com/embed/9gvnVj7DHuI",
      isRightAligned: true,
    },
  ];

  return (
    <section className={styles.cleaningSuggestionsSection}>
      <Timeline>
        {milestones.map((milestone, index) => (
          <Milestone
            key={index}
            title={milestone.title}
            description={milestone.description}
            image={milestone.image}
            videoUrl={milestone.videoUrl}
            isRightAligned={milestone.isRightAligned}
          />
        ))}
      </Timeline>
    </section>
  );
};

export default CleaningSuggestionsSection;
