import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CampaignCard.module.css";

interface Campaign {
  id: number;
  title: string;
  description: string;
  image: string;
  ctaText: string;
  ctaLink: string;
}

interface CampaignCardProps {
  campaign: Campaign;
}

const CampaignCard: React.FC<CampaignCardProps> = ({ campaign }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(campaign.ctaLink);
  };

  return (
    <div className={styles.card}>
      <div className={styles.imageWrapper}>
        <img src={campaign.image} alt={campaign.title} />
      </div>
      <div className={styles.content}>
        <h3>{campaign.title}</h3>
        <p>{campaign.description}</p>
        <button className={styles.button} onClick={handleButtonClick}>
          {campaign.ctaText}
        </button>
      </div>
    </div>
  );
};

export default CampaignCard;
