interface Location {
  id: string;
  name: string;
  address: string;
  description: string;
  email: string;
  phone: string;
  mapSrc: string;
}

const locations: Location[] = [
  {
    id: "Portugal",
    name: "Sede Importador Portugal",
    address: "IMPASSE FERNÃO LOPES, LT 40, LOJA ESQUERDA 2735-432 CACÉM",
    description: "Abertos das 14:00 às 21:00 de segunda a sexta.",
    email: "geral@ritelloportugal.com",
    phone: "+351 210-939-772",
    mapSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3110.7435698817364!2d-9.291966284661718!3d38.77029987958848!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ec4f24b7c9d8d%3A0xa19e06b9e5b833e6!2sRitello%20Portugal!5e0!3m2!1sen!2sus!4v1695933956456!5m2!1sen!2sus",
  },
];

export default locations;
