import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import RitelloFoundationHero from "../../assets/images/ritellofoundationhero.jpg";
import SocialResponsibilitySection from "./SocialResponsibilitySection";

const SocialResponsibilityPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Nav />
      <HeroSection
        title={t("social_responsibility_page.title")}
        subtitle={t("social_responsibility_page.subtitle")}
        backgroundImage={RitelloFoundationHero}
        variant="home"
      />

      <SocialResponsibilitySection />

      <Footer />
    </>
  );
};

export default SocialResponsibilityPage;
