// FeaturesSection.tsx

import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./FeaturesSection.module.css";
import Carousel from "../Carousel/Carousel"; // Adjust the path as needed
import {
  cordWinder,
  illuminatedSideWindows,
  illuminatedWaterBasin,
  ingeniousSlidingDoors,
  programmableLedScreen,
  rubberHandle,
  rubberWheels,
  speedButtons,
  turnLockMechanism,
} from "../../assets/images/features";

interface Feature {
  title: string;
  icon: string;
}

const FeaturesSection: React.FC = () => {
  const { t } = useTranslation();

  const features: Feature[] = [
    { title: t("features.cord_winder"), icon: cordWinder },
    {
      title: t("features.illuminated_side_windows"),
      icon: illuminatedSideWindows,
    },
    {
      title: t("features.illuminated_water_basin"),
      icon: illuminatedWaterBasin,
    },
    {
      title: t("features.ingenious_sliding_doors"),
      icon: ingeniousSlidingDoors,
    },
    {
      title: t("features.programmable_led_screen"),
      icon: programmableLedScreen,
    },
    { title: t("features.rubber_handle"), icon: rubberHandle },
    { title: t("features.rubber_wheels"), icon: rubberWheels },
    { title: t("features.speed_buttons"), icon: speedButtons },
    { title: t("features.turn_lock_mechanism"), icon: turnLockMechanism },
  ];

  // Render function for each feature item
  const renderFeatureItem = (feature: Feature, index: number) => (
    <div className={styles.card} key={index}>
      <img
        src={feature.icon}
        alt={`${feature.title} icon`}
        className={styles.featureImage}
      />
      <h3 className={styles.featureTitle}>{feature.title}</h3>
    </div>
  );

  return (
    <section className={styles.featuresSection}>
      <h2 className={styles.featuresTitle}>{t("features.title")}</h2>

      <Carousel
        items={features}
        renderItem={renderFeatureItem}
        settings={{
          slidesToShow: 3,
          // Only override specific settings; other settings like arrows remain intact
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                // You can include other overrides if needed
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                // You can include other overrides if needed
              },
            },
          ],
        }}
      />
    </section>
  );
};

export default FeaturesSection;
