import React, { useState, useEffect } from "react";
import MobileNav from "./MobileNav/MobileNav";
import DesktopNav from "./DesktopNav/DesktopNav";

const Nav: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1023);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1023);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header>
      {/* Render MobileNav if on a mobile screen, otherwise render DesktopNav */}
      {isMobile ? <MobileNav /> : <DesktopNav />}
    </header>
  );
};

export default Nav;
