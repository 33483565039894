import React from "react";
import styles from "./TermsOfServiceContent.module.css";

const TermsOfServiceContent: React.FC = () => {
  return (
    <div className={styles.termsOfServiceContainer}>
      <h1>Condições de Utilização</h1>
      <h3>
        POR FAVOR LEIA ATENTAMENTE O DOCUMENTO ABAIXO, QUE SE APLICA AO PRESENTE
        SITE E A TODO O MATERIAL NELE CONTIDO:
      </h3>
      <p>
        Se eventualmente a encomenda não for entregue nas melhores condições,
        agradecemos que entre em contacto connosco para resolvermos a situação.
      </p>
      <h3>
        De acordo com Decreto-Lei n.º 143/2001, Artigo 6.º, as devoluções
        poderão ser efectuadas de acordo com as seguintes cláusulas:
      </h3>
      <p></p>
      <h3>
        1 - Nos contratos à distância o consumidor dispõe de um prazo mínimo de
        14 dias para resolver o contrato sem pagamento de indemnização e sem
        necessidade de indicar o motivo.
      </h3>

      <h3>2 - Para o exercício desse direito, o prazo conta-se:</h3>
      <p>
        a) No que se refere ao fornecimento de bens, a partir do dia da sua
        recepção pelo consumidor sempre que tenham sido cumpridas as obrigações
        referidas no artigo 5.º deste mesmo decreto;
      </p>
      <p>
        b) No que se refere à prestação de serviços, a partir do dia da
        celebração do contrato ou a partir do dia em que tenham sido cumpridas
        as obrigações referidas no artigo 5.º deste decreto, se tal suceder após
        aquela celebração, desde que não se exceda o prazo de três meses
        referido no número seguinte;
      </p>
      <p>
        c) Se o fornecedor não tiver cumprido as obrigações referidas no artigo
        5.º deste decreto, o prazo referido no n.º 1 é de três meses a contar da
        data da recepção dos bens pelo consumidor ou, tratando-se de serviços,
        da data da celebração do contrato;
      </p>
      <p>
        d) Caso o fornecedor venha a cumprir as obrigações referidas no artigo
        5.º deste decreto, no decurso do prazo de resolução referido no número
        anterior e antes de o consumidor ter exercido esse direito, este dispõe
        de 14 dias para resolver o contrato a partir da data de recepção dessas
        informações.
      </p>
      <h3>
        3 - Se o fornecedor não tiver cumprido as obrigações referidas no artigo
        7.º deste decreto, o prazo referido no n.º 1 é de três meses a contar da
        data da recepção dos bens pelo consumidor ou, tratando-se de serviços,
        da data da celebração do contrato.
      </h3>

      <h3>
        4 - Caso o fornecedor venha a cumprir as obrigações referidas no artigo
        7.º deste decreto, no decurso do prazo de resolução referido no número
        anterior e antes de o consumidor ter exercido esse direito, este dispõe
        de 14 dias para resolver o contrato a partir do recebimento dessas
        informações.
      </h3>

      <h3>
        5 - Sem prejuízo do estabelecido na alínea a) do n.º 3 do artigo
        anterior, considera-se exercido o direito de resolução pelo consumidor
        através da expedição, nos prazos aqui previstos, de carta registada com
        aviso de recepção comunicando ao outro contraente ou à pessoa para tal
        designada a vontade de resolver o contrato.
      </h3>
    </div>
  );
};

export default TermsOfServiceContent;
