import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import Hero3 from "../../assets/images/hero3.png";
import ProductGrid from "../../components/ProductComponents/ProductGrid/ProductGrid";

const ProductPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Nav />
      <HeroSection
        title={t("product_page.title")}
        subtitle={t("product_page.subtitle")}
        backgroundImage={Hero3}
      />
      <ProductGrid />
      <Footer />
    </>
  );
};

export default ProductPage;
