import React from "react";
import styles from "./SplitImageSection.module.css";

interface SplitImageSectionProps {
  image: string;
  title: string;
  description: string;
  buttonText1?: string;
  buttonLink1?: string;
  buttonText2?: string;
  buttonLink2?: string;
  buttonText3?: string;
  buttonLink3?: string;
}

const SplitImageSection: React.FC<SplitImageSectionProps> = ({
  image,
  title,
  description,
  buttonText1,
  buttonLink1,
  buttonText2,
  buttonLink2,
  buttonText3,
  buttonLink3,
}) => {
  return (
    <section className={styles.splitSection}>
      <div className={styles.imageContainer}>
        <img src={image} alt={title} className={styles.image} />
      </div>
      <div className={styles.content}>
        <h2>{title}</h2>
        <p>{description}</p>
        {buttonText1 && buttonLink1 && (
          <a href={buttonLink1} className={styles.ctaButton}>
            {buttonText1}
          </a>
        )}
        {buttonText2 && buttonLink2 && (
          <a href={buttonLink2} className={styles.ctaButton}>
            {buttonText2}
          </a>
        )}
        {buttonText3 && buttonLink3 && (
          <a href={buttonLink3} className={styles.ctaButton}>
            {buttonText3}
          </a>
        )}
      </div>
    </section>
  );
};

export default SplitImageSection;
