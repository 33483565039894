// pages/ContactPage/ContactPage.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import ContactForm from "../../components/ContactForm/ContactForm";
import CompanyInfo from "../../components/CompanyInfo/CompanyInfo";
import LibrexBuilding from "../../assets/images/contacthero.webp";
import styles from "./ContactPage.module.css";

const ContactPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Nav />
      <HeroSection
        title={t("contact_page.title")}
        subtitle={t("contact_page.subtitle")}
        backgroundImage={LibrexBuilding}
      />
      <div className={styles.contactSection}>
        <CompanyInfo />
        <ContactForm />
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
