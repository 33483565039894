import Transferencia from "../../assets/images/payment-methods/Transferenciabancaria.png";
import MbwayLogo from "../../assets/images/payment-methods/mbway-logo.png";
import styles from "./PaymentMethods.module.css";

const PaymentMethods: React.FC = () => {
  return (
    <section className={styles.paymentMethods}>
      <h3 className={styles.paymentTitle}>Métodos de Pagamento</h3>
      <div className={styles.paymentLogos}>
        <img src={MbwayLogo} alt="Mbway" className={styles.paymentLogo} />
        <img
          src={Transferencia}
          alt="Transferencia"
          className={styles.paymentLogo}
        />
      </div>
    </section>
  );
};

export default PaymentMethods;
