import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import Hero3 from "../../assets/images/hero3.png";
import Campaign1 from "../../assets/images/campaigns/FeriasAlgarve.png";
// import Campaign2 from "../../assets/images/campaigns/FeriasVilamoura.png";
import Campaign3 from "../../assets/images/purello/Purello.jpg";
import Campaign4 from "../../assets/images/campaigns/FerroIngomarSemFios.png";
import CampaignList from "../../components/CampaignList/CampaignList";
import styles from "./CampaignPage.module.css";
import { useParams } from "react-router-dom";

const CampaignPage: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const currentLanguage = lng || "pt";

  const campaigns = [
    {
      id: 1,
      title: t("campaigns.campaign1.title"),
      description: t("campaigns.campaign1.description"),
      image: Campaign1,
      ctaText: t("campaigns.campaign1.cta_text"),
      ctaLink: `/${currentLanguage}${t("campaigns.campaign1.cta_link")}`,
    },
    // {
    //   id: 2,
    //   title: t("campaigns.campaign2.title"),
    //   description: t("campaigns.campaign2.description"),
    //   image: Campaign2,
    //   ctaText: t("campaigns.campaign2.cta_text"),
    //   ctaLink: `/${currentLanguage}${t("campaigns.campaign2.cta_link")}`,
    // },
    {
      id: 3,
      title: t("campaigns.campaign3.title"),
      description: t("campaigns.campaign3.description"),
      image: Campaign3,
      ctaText: t("campaigns.campaign3.cta_text"),
      ctaLink: `/${currentLanguage}${t("campaigns.campaign3.cta_link")}`,
    },
    {
      id: 4,
      title: t("campaigns.campaign4.title"),
      description: t("campaigns.campaign4.description"),
      image: Campaign4,
      ctaText: t("campaigns.campaign4.cta_text"),
      ctaLink: `/${currentLanguage}${t("campaigns.campaign4.cta_link")}`,
    },
  ];

  return (
    <>
      <Nav />
      <HeroSection
        title={t("campaign_page.title")}
        subtitle={t("campaign_page.subtitle")}
        backgroundImage={Hero3}
      />
      <div className={styles.campaignSection}>
        <CampaignList campaigns={campaigns} />
      </div>
      <Footer />
    </>
  );
};

export default CampaignPage;
