import React from "react";
import styles from "./CallToAction.module.css";

interface CallToActionProps {
  text: string;
  buttonText: string;
  buttonLink: string;
}

const CallToAction: React.FC<CallToActionProps> = ({
  text,
  buttonText,
  buttonLink,
}) => {
  return (
    <section className={styles.ctaSection}>
      <p>{text}</p>
      <a href={buttonLink} className={styles.ctaButton}>
        {buttonText}
      </a>
    </section>
  );
};

export default CallToAction;
