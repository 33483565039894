import { useEffect } from "react";

const StripFbclidParam = () => {
  useEffect(() => {
    if (window.location.search.includes("fbclid")) {
      const url = new URL(window.location.href);
      url.searchParams.delete("fbclid");
      window.history.replaceState({}, document.title, url.toString());
    }
  }, []);

  return null;
};

export default StripFbclidParam;
