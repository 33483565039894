// pages/VacationFormPage/VacationFormPage.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import GoogleFormEmbed from "../../components/GoogleFormEmbed/GoogleFormEmbed";
import LibrexBuilding from "../../assets/images/campaigns/FeriasAlgarve.png";
import styles from "./VacationFormPage.module.css";

const VacationFormPage: React.FC = () => {
  const { t } = useTranslation();
  const googleFormUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLScPtHoJe4gXGu-5O_HbihD-6Q3CzkyHQhGjEUQkfAgMFQ-DYA/viewform?embedded=true"; // Replace with actual Google Form embed URL

  return (
    <>
      <Nav />
      <HeroSection
        title={t("vacation_form_page.title")}
        subtitle={t("vacation_form_page.subtitle")}
        backgroundImage={LibrexBuilding}
      />
      <div className={styles.vacationFormSection}>
        <GoogleFormEmbed formUrl={googleFormUrl} />
      </div>
      <Footer />
    </>
  );
};

export default VacationFormPage;
