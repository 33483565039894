// Carousel.tsx

import React from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Carousel.module.css";

interface CarouselComponentProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => React.ReactNode;
  settings?: Settings;
}

const CarouselComponent = <T,>({
  items,
  renderItem,
  settings,
}: CarouselComponentProps<T>) => {
  const defaultSettings: Settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: false,
  };

  const carouselSettings: Settings = { ...defaultSettings, ...settings };

  return (
    <div className={styles.carouselContainer}>
      <Slider {...carouselSettings}>
        {items.map((item: T, index: number) => (
          <div key={index} className={styles.carouselItem}>
            {renderItem(item, index)}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselComponent;
