import React from "react";
import styles from "./Milestone.module.css";

interface MilestoneProps {
  title: string;
  description: string;
  image?: string;
  videoUrl?: string;
  isRightAligned: boolean;
}

const Milestone: React.FC<MilestoneProps> = ({
  title,
  description,
  image,
  videoUrl,
  isRightAligned,
}) => {
  return (
    <div
      className={`${styles.milestone} ${
        isRightAligned ? styles.rightAligned : styles.leftAligned
      } ${image ? styles.hasImage : videoUrl ? styles.hasVideo : ""}`}
    >
      <div className={styles.mediaContainer}>
        {image ? (
          <img src={image} alt={title} />
        ) : videoUrl ? (
          <iframe
            src={videoUrl}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : null}
      </div>
      <div className={styles.textContainer}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Milestone;
