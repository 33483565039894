// src/pages/FAQPage/FAQSection.tsx
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FAQSection.module.css";

interface FAQ {
  question: string;
  answer: string;
}

const FAQSection: React.FC = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqData = t("faq_section.faqs", { returnObjects: true }) as FAQ[];

  const toggleFAQ = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.faqContainer}>
      {faqData.map((faq, index) => (
        <div key={index} className={styles.faqItem}>
          <div
            className={styles.question}
            onClick={() => toggleFAQ(index)}
            aria-expanded={activeIndex === index}
          >
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className={styles.answer}>{faq.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
