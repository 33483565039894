import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TestimonialsSection from "./TestimonialsSection";

interface Testimonial {
  name: string;
  quote: string;
  avatar?: string;
}

const Testimonials: React.FC = () => {
  const { t } = useTranslation();

  // Memoize the customer testimonials to prevent constant re-renders
  const customerTestimonials = useMemo(() => {
    return t("testimonials.customers", {
      returnObjects: true,
    }) as Testimonial[];
  }, [t]);

  const [visibleTestimonials, setVisibleTestimonials] = useState<Testimonial[]>(
    []
  );
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Set the first 3 testimonials to display
    setVisibleTestimonials(customerTestimonials.slice(0, 3));

    const interval = setInterval(() => {
      // Rotate the testimonials every 30 seconds
      setCurrentIndex(
        (prevIndex) => (prevIndex + 3) % customerTestimonials.length
      );
    }, 30000);

    return () => clearInterval(interval);
  }, [customerTestimonials]);

  useEffect(() => {
    // Update the visible testimonials based on the current index
    const nextTestimonials = customerTestimonials.slice(
      currentIndex,
      currentIndex + 3
    );

    // Loop back to the beginning if needed
    if (nextTestimonials.length < 3) {
      setVisibleTestimonials([
        ...nextTestimonials,
        ...customerTestimonials.slice(0, 3 - nextTestimonials.length),
      ]);
    } else {
      setVisibleTestimonials(nextTestimonials);
    }
  }, [currentIndex, customerTestimonials]);

  return (
    <TestimonialsSection
      testimonials={visibleTestimonials}
      title={t("testimonials.title")}
    />
  );
};

export default Testimonials;
