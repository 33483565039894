import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Certificates.module.css";
import certificates from "../../data/certificates";

// Separate big and small certificates
const bigCertificates = certificates.filter((cert) => cert.isBig);
const smallCertificates = certificates.filter((cert) => !cert.isBig);

const Certificates: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.certificatesSection}>
      {/* Translate the page description */}
      <p className={styles.description}>{t("certificates_page.description")}</p>

      {/* Display big certificates with title and description */}
      <div className={styles.bigCertificatesWrapper}>
        {bigCertificates.map((cert) => (
          <div key={cert.id} className={styles.bigCertificate}>
            <img
              src={cert.img}
              alt={t(`certificates.${cert.id}.title`)}
              className={styles.bigCertificateImage}
            />
            <h3 className={styles.bigCertificateTitle}>
              {t(`certificates.${cert.id}.title`)}
            </h3>
            <p className={styles.bigCertificateDescription}>
              {t(`certificates.${cert.id}.description`)}
            </p>
          </div>
        ))}
      </div>

      {/* Display small certificates without title/description */}
      <div className={styles.certificateScroller}>
        <div className={styles.scrollerContent}>
          {smallCertificates.map((cert) => (
            <div key={cert.id} className={styles.certificateItem}>
              <img
                src={cert.img}
                alt={t(`certificates.${cert.id}.title`)}
                className={styles.certificateImage}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Certificates;
