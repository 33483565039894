// src/pages/CleaningSuggestionsPage/CleaningSuggestionsPage.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import LibrexBuilding from "../../assets/images/contacthero.webp";
import CleaningSuggestionsSection from "./CleaningSuggestionsSection";

const CleaningSuggestionsPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Nav />
      <HeroSection
        title={t("cleaning_suggestions_page.title")}
        subtitle=""
        backgroundImage={LibrexBuilding}
      />
      <div>
        <CleaningSuggestionsSection />
      </div>
      <Footer />
    </>
  );
};

export default CleaningSuggestionsPage;
