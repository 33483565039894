interface Certificate {
  id: string;
  title?: string;
  description?: string;
  img: string;
  isBig?: boolean; // Added to identify the big certificate
}

const certificates: Certificate[] = [
  {
    id: "big_certificate_1",
    img: require("../assets/images/certificates/certificates-main.png"),
    isBig: true,
  },
  {
    id: "big_certificate_2",
    img: require("../assets/images/certificates/certificado_cruzvermelha.png"),
    isBig: true,
  },
  {
    id: "big_certificate_3",
    img: require("../assets/images/certificates/certificado_italiano.png"),
    isBig: true,
  },
  {
    id: "big_certificate_4",
    img: require("../assets/images/certificates/certificado_conformidade.png"),
    isBig: true,
  },
  {
    id: "big_certificate_5",
    img: require("../assets/images/certificates/Certificado-Ibr.jpg"),
    isBig: true,
  },
  {
    id: "cert6",
    img: require("../assets/images/certificates/Certificado3.png"),
  },
  {
    id: "cert7",
    img: require("../assets/images/certificates/Certificado4.png"),
  },
  {
    id: "cert8",
    img: require("../assets/images/certificates/Certificado5.png"),
  },
  {
    id: "cert9",
    img: require("../assets/images/certificates/Certificado6.png"),
  },
  {
    id: "cert10",
    img: require("../assets/images/certificates/Certificado7.png"),
  },
];

export default certificates;
