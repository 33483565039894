// HomePage.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Features from "../../components/FeaturesSection/FeaturesSection";
import Testimonials from "../../components/Testimonials/Testimonials";
import ProductHighlights from "../../components/ProductComponents/ProductHighlights/ProductHighlights";
import CallToAction from "../../components/CallToAction/CallToAction";
import Footer from "../../components/Footer/Footer";
import About1 from "../../assets/images/about-us.png";
import Hero3 from "../../assets/images/hero3.png";
import { useParams } from "react-router-dom";
import BackgroundSection1 from "../../assets/images/top-section-bg-op_01.jpg";
import BgHepaSection from "../../assets/images/top-section-hepa-bg_01.jpg";
import BgSeparatorSection from "../../assets/images/bottom-section-separator-bg_01.jpg";
import BannerSection from "../../components/Sections/BannerSection";
import SplitImageSection from "../../components/Sections/SplitImageSection";
import Hepa from "../../assets/images/hepa.png";
import Separator from "../../assets/images/separator.png";
import Ritello from "../../assets/images/Ritello.png";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { lng } = useParams<{ lng: string }>();
  const currentLanguage = lng || "pt";

  return (
    <>
      <Nav />
      <HeroSection
        title={t("home.hero_title")}
        subtitle={t("home.hero_subtitle")}
        ctaText={t("home.hero_cta_text")}
        ctaLink={`/${currentLanguage}${t("home.hero_cta_link")}`}
        backgroundImage={Hero3}
        variant="home"
      />

      {/* Neutralizador */}
      <BannerSection
        desktopBackgroundImage={BgHepaSection}
        mobileImage={Hepa}
        title={t("neutralizer_section.title")}
        description={t("neutralizer_section.description")}
        // videoButtonText={t("neutralizer_section.video_button_text")}
        // videoButtonOnClick={() => {
        //   // Handle video button click, e.g., open a modal or navigate to a video page
        // }}
        demoButtonText={t("neutralizer_section.demo_button_text")}
        demoButtonLink={`/${currentLanguage}${t("routes.get_price")}`}
        altText="Ritello"
        contentPosition="right"
      />
      {/* Separator */}
      <BannerSection
        desktopBackgroundImage={BgSeparatorSection}
        mobileImage={Separator}
        title={t("separator_section.title")}
        description={t("separator_section.description")}
        // videoButtonText={t("separator_section.video_button_text")}
        // videoButtonOnClick={() => {
        //   // Handle video button click, e.g., open a modal or navigate to a video page
        // }}
        // demoButtonText={t("separator_section.demo_button_text")}
        // demoButtonLink={`/${currentLanguage}${t("routes.get_price")}`}
        altText="Ritello"
        contentPosition="left"
      />

      {/* Cost */}
      <BannerSection
        desktopBackgroundImage={BackgroundSection1}
        mobileImage={Ritello}
        title={t("cost_section.title")}
        description={t("cost_section.description")}
        demoButtonText={t("cost_section.demo_button_text")}
        demoButtonLink={`/${currentLanguage}${t("routes.get_price")}`}
        altText="Ritello"
        contentPosition="right"
      />
      <Features />
      <ProductHighlights />
      {/* About Us */}
      <SplitImageSection
        image={About1}
        title={t("aboutus_section.title")}
        description={t("aboutus_section.description")}
        buttonText1={t("nav.our_history")}
        buttonLink1={`/${currentLanguage}${t("routes.our_history")}`}
        buttonText2={t("nav.social_responsibility")}
        buttonLink2={`/${currentLanguage}${t("routes.social_responsibility")}`}
        buttonText3={t("nav.certificates")}
        buttonLink3={`/${currentLanguage}${t("routes.certificates")}`}
      />
      <Testimonials />
      <CallToAction
        text={t("home.call_to_action_text")}
        buttonText={t("home.call_to_action_button_text")}
        buttonLink={`/${currentLanguage}${t(
          "home.call_to_action_button_link"
        )}`}
      />
      <Footer />
    </>
  );
};

export default HomePage;
