// src/components/Navigation/DesktopNav/DesktopNav.tsx
import React, { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import styles from "./DesktopNav.module.css";
import Logo from "../../../assets/images/Logo.png";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import NavGridItem from "./NavGridItem";
import Almofadas from "../../../assets/images/limpar-menu/almofadas1.png";
import Ar from "../../../assets/images/limpar-menu/ar1.png";
import Carro from "../../../assets/images/limpar-menu/carro1.png";
import Chao from "../../../assets/images/limpar-menu/chao1.png";
import Colchao from "../../../assets/images/limpar-menu/colchao1.png";
import Estofos from "../../../assets/images/limpar-menu/estofos1.png";
import Janela from "../../../assets/images/limpar-menu/janela1.png";
import Moveis from "../../../assets/images/limpar-menu/moveis1.png";
import Paredes from "../../../assets/images/limpar-menu/paredes1.png";
import Pelos from "../../../assets/images/limpar-menu/cat2.png";
import Sofa from "../../../assets/images/limpar-menu/sofa1.png";
import Tapetes from "../../../assets/images/limpar-menu/tapetes2.png";

const DesktopNav: React.FC = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt";
  const { t } = useTranslation();

  // Map keys to images
  const imagesMap: { [key: string]: string } = {
    pillows_and_blankets: Almofadas,
    air: Ar,
    auto: Carro,
    floor: Chao,
    mattress: Colchao,
    upholstery: Estofos,
    window: Janela,
    furniture: Moveis,
    walls: Paredes,
    hair: Pelos,
    sofa: Sofa,
    carpets: Tapetes,
  };

  // Get the cleaning items from translations
  const cleaningItems = t("nav_cleaning_items", { returnObjects: true }) as {
    [key: string]: {
      title: string;
      path: string;
    };
  };

  return (
    <header id="header" className={styles.header}>
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <div className={styles.grid}>
            <NavLink
              className={styles.logoLink}
              to={`/${language}${t("routes.home")}`}
            >
              <img src={Logo} alt="Ritello Logo" className={styles.logoImage} />
            </NavLink>

            {/* Top right links */}
            <div className={styles.topRight}>
              <NavLink
                className={styles.teamLink}
                to={`/${language}${t("routes.social_responsibility")}`}
              >
                {t("nav.social_responsibility")}
              </NavLink>

              <div className={styles.ctas}>
                <NavLink
                  className={styles.secondaryCta}
                  to={`/${language}${t("routes.become_agent")}`}
                >
                  {t("nav.become_agent")}
                  <FaArrowRight className={styles.ctaIcon} />
                </NavLink>

                <NavLink
                  className={styles.primaryCta}
                  to={`/${language}${t("routes.get_price")}`}
                >
                  {t("nav.get_price")}
                  <FaArrowRight className={styles.ctaIcon} />
                </NavLink>
              </div>

              <LanguageSelector />
            </div>
          </div>

          {/* Nav Links */}
          <nav className={styles.navMenu}>
            <ul className={styles.navList}>
              <li className={styles.navItem}>
                <NavLink
                  className={styles.navLink}
                  to={`/${language}${t("routes.our_history")}`}
                >
                  {t("nav.ritello_r2")}
                </NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink
                  className={styles.navLink}
                  to={`/${language}${t("routes.products")}`}
                >
                  {t("nav.acessorios")}
                </NavLink>
              </li>
              <li
                className={styles.navItemWithDropdown}
                onMouseEnter={() => setDropdownVisible(true)}
                onMouseLeave={() => setDropdownVisible(false)}
              >
                {/* <span className={styles.navLink}>
                  {t("nav.what_do_you_want_to_clean")}
                </span> */}
                {/* Full-Width Dropdown */}
                {dropdownVisible && (
                  <div className={styles.fullWidthDropdown}>
                    <div className={styles.dropdownGrid}>
                      {Object.keys(cleaningItems).map((key) => (
                        <NavGridItem
                          key={key}
                          imageSrc={imagesMap[key]}
                          title={cleaningItems[key].title}
                          link={`/${language}${cleaningItems[key].path}`}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </li>
              <li className={styles.navItem}>
                <NavLink
                  className={styles.navLink}
                  to={`/${language}${t("routes.campaigns")}`}
                >
                  {t("nav.campaigns")}
                </NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink
                  className={styles.navLink}
                  to={`/${language}${t("routes.our_history")}`}
                >
                  {t("nav.history")}
                </NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink
                  className={styles.navLink}
                  to={`/${language}${t("routes.certificates")}`}
                >
                  {t("nav.certificates")}
                </NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink
                  className={styles.navLink}
                  to={`/${language}${t("routes.assistance")}`}
                >
                  {t("nav.assistance")}
                </NavLink>
              </li>
              <li className={styles.navItem}>
                <NavLink
                  className={styles.navLink}
                  to={`/${language}${t("routes.contact")}`}
                >
                  {t("nav.contact")}
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default DesktopNav;
