import React from "react";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import HeroCertificate from "../../assets/images/certificates/certified-ritello-website.png";
import Certificates from "../../components/Certificates/Certificates";
import { useTranslation } from "react-i18next";

const CertificatePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Nav />
      <HeroSection
        title={t("certificate_page.title")}
        subtitle=""
        backgroundImage={HeroCertificate}
      />
      <Certificates />
      <Footer />
    </>
  );
};

export default CertificatePage;
