import React from "react";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import Hero3 from "../../assets/images/hero3.png";
import TermsOfServiceContent from "../../components/TermsOfServiceContent/TermsOfServiceContent";

const TermsOfServicePage: React.FC = () => {
  return (
    <>
      <Nav />
      <HeroSection
        title="Termos de Serviço"
        subtitle=""
        backgroundImage={Hero3}
      />
      <TermsOfServiceContent />
      <Footer />
    </>
  );
};

export default TermsOfServicePage;
