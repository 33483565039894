import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import styles from "./ContactForm.module.css";

type FormData = {
  from_name: string;
  email: string;
  phone: string;
  subject: string;
  location: string;
  message: string;
};

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const sendEmail: SubmitHandler<FormData> = (data) => {
    emailjs
      .send("service_wizymj4", "template_gutxvhp", data, "kA0TDkpXEVT1HlrvD")
      .then(
        () => {
          console.log("Email sent successfully!");
          reset();
        },
        (error) => {
          console.log("Failed to send email...", error);
        }
      );
  };

  return (
    <section className={styles.contactFormSection}>
      <h2>{t("contact_form.title")}</h2>
      <form onSubmit={handleSubmit(sendEmail)} className={styles.contactForm}>
        <input
          type="text"
          {...register("from_name", {
            required: t("contact_form.errors.name_required") as string,
          })}
          placeholder={t("contact_form.fields.name")}
          className={errors.from_name ? styles.errorInput : ""}
        />
        {errors.from_name && (
          <p className={styles.errorMessage}>{errors.from_name.message}</p>
        )}

        <input
          type="email"
          {...register("email", {
            required: t("contact_form.errors.email_required") as string,
            pattern: {
              value: /^\S+@\S+$/i,
              message: t("contact_form.errors.email_invalid"),
            },
          })}
          placeholder={t("contact_form.fields.email")}
          className={errors.email ? styles.errorInput : ""}
        />
        {errors.email && (
          <p className={styles.errorMessage}>{errors.email.message}</p>
        )}

        <input
          type="tel"
          {...register("phone", {
            required: t("contact_form.errors.phone_required") as string,
            pattern: {
              value: /^[0-9]{9,}$/,
              message: t("contact_form.errors.phone_invalid"),
            },
          })}
          placeholder={t("contact_form.fields.phone")}
          className={errors.phone ? styles.errorInput : ""}
        />
        {errors.phone && (
          <p className={styles.errorMessage}>{errors.phone.message}</p>
        )}

        <input
          type="text"
          {...register("subject", {
            required: t("contact_form.errors.subject_required") as string,
          })}
          placeholder={t("contact_form.fields.subject")}
          className={errors.subject ? styles.errorInput : ""}
        />
        {errors.subject && (
          <p className={styles.errorMessage}>{errors.subject.message}</p>
        )}

        <input
          type="text"
          {...register("location", {
            required: t("contact_form.errors.location_required") as string,
          })}
          placeholder={t("contact_form.fields.location")}
          className={errors.location ? styles.errorInput : ""}
        />
        {errors.location && (
          <p className={styles.errorMessage}>{errors.location.message}</p>
        )}

        <textarea
          {...register("message", {
            required: t("contact_form.errors.message_required") as string,
          })}
          placeholder={t("contact_form.fields.message")}
          className={errors.message ? styles.errorInput : ""}
        />
        {errors.message && (
          <p className={styles.errorMessage}>{errors.message.message}</p>
        )}

        <button type="submit">{t("contact_form.submit_button")}</button>
      </form>
    </section>
  );
};

export default ContactForm;
