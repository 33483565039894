import React from "react";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import PrivacyPolicyContent from "../../components/PrivacyPolicyContent/PrivacyPolicyContent";
import Footer from "../../components/Footer/Footer";
import Hero3 from "../../assets/images/hero3.png";

const PrivacyPage: React.FC = () => {
  return (
    <>
      <Nav />
      <HeroSection
        title="Politica de Privacidade"
        subtitle=""
        backgroundImage={Hero3}
      />
      <PrivacyPolicyContent />
      <Footer />
    </>
  );
};

export default PrivacyPage;
