// src/components/Navigation/MobileNav/MobileNav.tsx
import React, { useState } from "react";
import { FaChevronDown, FaChevronRight, FaTimes, FaBars } from "react-icons/fa";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./MobileNav.module.css";
import Logo from "../../../assets/images/Logo.png";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";

interface NavItem {
  name: string;
  path: string;
  subItems?: NavItem[];
}

const MobileNav: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const { lng } = useParams<{ lng: string }>();
  const language = lng || "pt"; // Default to 'pt' if undefined
  const { t } = useTranslation();

  const navItems: NavItem[] = [
    { name: t("nav.home"), path: `/${language}${t("routes.home")}` },
    { name: t("nav.products"), path: `/${language}${t("routes.products")}` },
    { name: t("nav.campaigns"), path: `/${language}${t("routes.campaigns")}` },
    {
      name: t("nav.about_us"),
      path: "#",
      subItems: [
        {
          name: t("nav.our_history"),
          path: `/${language}${t("routes.our_history")}`,
        },
        {
          name: t("nav.social_responsibility"),
          path: `/${language}${t("routes.social_responsibility")}`,
        },
        {
          name: t("nav.certificates"),
          path: `/${language}${t("routes.certificates")}`,
        },
      ],
    },
    { name: t("nav.contact"), path: `/${language}${t("routes.contact")}` },
    {
      name: t("nav.help"),
      path: "#",
      subItems: [
        {
          name: t("nav.assistance"),
          path: `/${language}${t("routes.assistance")}`,
        },
        {
          name: t("nav.cleaning_suggestions"),
          path: `/${language}${t("routes.cleaning_suggestions")}`,
        },
        { name: t("nav.faq"), path: `/${language}${t("routes.faq")}` },
      ],
    },
  ];

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleMenuToggle = (name: string) => {
    setActiveMenu((prev) => (prev === name ? null : name));
  };

  return (
    <header className={styles.fullwidth}>
      <div className={styles.minHeight}>
        <div className={styles.menuContainer}>
          <NavLink
            to={`/${language}${t("routes.home")}`}
            className={styles.logoLink}
          >
            <img src={Logo} alt="Ritello Logo" className={styles.logoImage} />
          </NavLink>
          <div className={styles.iconContainer}>
            <LanguageSelector />
            <button
              className={styles.hamburger}
              onClick={toggleMenu}
              aria-label="Toggle Menu"
            >
              {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <nav className={styles.mobileNav}>
          <div className={styles.navWrapper}>
            <ul className={styles.navList}>
              {navItems.map((item) => (
                <li key={item.name} className={styles.menuItem}>
                  {item.subItems ? (
                    <>
                      <button
                        onClick={() => handleMenuToggle(item.name)}
                        aria-expanded={activeMenu === item.name}
                        className={styles.menuLink}
                      >
                        {item.name}
                        <span
                          className={`${styles.arrowIcon} ${
                            activeMenu === item.name ? styles.rotated : ""
                          }`}
                        >
                          <FaChevronDown />
                        </span>
                      </button>
                      {activeMenu === item.name && (
                        <ul className={`${styles.submenu} ${styles.visible}`}>
                          {item.subItems.map((subItem) => (
                            <li
                              key={subItem.name}
                              className={styles.submenuItem}
                            >
                              <NavLink
                                to={subItem.path}
                                className={styles.submenuLink}
                                onClick={toggleMenu}
                              >
                                {subItem.name}
                                <FaChevronRight
                                  className={styles.arrowIconRight}
                                />
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    <NavLink
                      to={item.path}
                      className={styles.menuLink}
                      onClick={toggleMenu}
                    >
                      {item.name}
                      <FaChevronRight className={styles.arrowIconRight} />
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>

            {/* Fixed bottom menu items */}
            <div className={styles.fixedMenuItems}>
              <ul className={styles.fixedMenuList}>
                <li className={`${styles.menuItem} ${styles.menuGray}`}>
                  <NavLink
                    to={`/${language}${t("routes.become_agent")}`}
                    className={styles.menuLink}
                    onClick={toggleMenu}
                  >
                    {t("nav.become_agent")}
                    <FaChevronRight className={styles.arrowIconRight} />
                  </NavLink>
                </li>
                <li className={`${styles.menuItem} ${styles.menuBlue}`}>
                  <NavLink
                    to={`/${language}${t("routes.get_price")}`}
                    className={styles.menuLink}
                    onClick={toggleMenu}
                  >
                    {t("nav.get_price")}
                    <FaChevronRight className={styles.arrowIconRight} />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
    </header>
  );
};

export default MobileNav;
