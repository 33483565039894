// components/GoogleFormEmbed/GoogleFormEmbed.tsx
import React from "react";
import styles from "./GoogleFormEmbed.module.css";

interface GoogleFormEmbedProps {
  formUrl: string;
}

const GoogleFormEmbed: React.FC<GoogleFormEmbedProps> = ({ formUrl }) => {
  return (
    <div className={styles.formContainer}>
      <iframe
        src={formUrl}
        style={{ border: "none" }}
        width="100%"
        height="800"
        title="Google Form"
      >
        Loading…
      </iframe>
    </div>
  );
};

export default GoogleFormEmbed;
