import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavGridItem.module.css";

interface NavGridItemProps {
  imageSrc: string;
  title: string;
  link: string;
}

const NavGridItem: React.FC<NavGridItemProps> = ({ imageSrc, title, link }) => {
  return (
    <NavLink to={link} className={styles.gridItem}>
      <div className={styles.imageWrapper}>
        <img src={imageSrc} alt={title} className={styles.image} />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
      </div>
    </NavLink>
  );
};

export default NavGridItem;
