// src/components/HistoryComponents/HistorySection/HistorySection.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import Milestone1 from "../../../assets/images/milestones/milestone1.webp";
import Milestone2 from "../../../assets/images/milestones/milestone2.webp";
import Milestone3 from "../../../assets/images/milestones/milestone3.webp";
import Milestone4 from "../../../assets/images/milestones/milestone4.webp";
import Milestone5 from "../../../assets/images/milestones/milestone5.webp";
import Milestone6 from "../../../assets/images/milestones/milestone6.webp";
import styles from "./HistorySection.module.css";
import Timeline from "../Timeline/Timeline";
import Milestone from "../Milestone/Milestone";

const HistorySection: React.FC = () => {
  const { t } = useTranslation();

  const milestones = [
    {
      id: 1,
      title: t("history.milestone1.title"),
      description: t("history.milestone1.description"),
      image: Milestone1,
      isRightAligned: false,
    },
    {
      id: 2,
      title: t("history.milestone2.title"),
      description: t("history.milestone2.description"),
      image: Milestone2,
      isRightAligned: true,
    },
    {
      id: 3,
      title: t("history.milestone3.title"),
      description: t("history.milestone3.description"),
      image: Milestone3,
      isRightAligned: false,
    },
    {
      id: 4,
      title: t("history.milestone4.title"),
      description: t("history.milestone4.description"),
      image: Milestone4,
      isRightAligned: true,
    },
    {
      id: 5,
      title: t("history.milestone5.title"),
      description: t("history.milestone5.description"),
      image: Milestone5,
      isRightAligned: false,
    },
    {
      id: 6,
      title: t("history.milestone6.title"),
      description: t("history.milestone6.description"),
      image: Milestone6,
      isRightAligned: true,
    },
  ];

  return (
    <section className={styles.historySection}>
      <Timeline>
        {milestones.map((milestone) => (
          <Milestone
            key={milestone.id}
            title={milestone.title}
            description={milestone.description}
            image={milestone.image}
            isRightAligned={milestone.isRightAligned}
          />
        ))}
      </Timeline>
    </section>
  );
};

export default HistorySection;
