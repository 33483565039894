import React from "react";
import styles from "./BannerSection.module.css";
import { FaPlayCircle, FaArrowRight } from "react-icons/fa";

interface BannerSectionProps {
  desktopBackgroundImage: string;
  mobileImage: string;
  title: string;
  description: string;
  videoButtonText?: string;
  videoButtonOnClick?: () => void;
  demoButtonText?: string;
  demoButtonLink?: string;
  altText?: string;
  contentPosition?: "left" | "right"; // New prop for content position
}

const BannerSection: React.FC<BannerSectionProps> = ({
  desktopBackgroundImage,
  mobileImage,
  title,
  description,
  videoButtonText,
  videoButtonOnClick,
  demoButtonText,
  demoButtonLink,
  altText,
  contentPosition = "left", // Default to "left"
}) => {
  return (
    <section
      className={styles.sectionContainer}
      style={
        {
          "--desktop-bg-image": `url(${desktopBackgroundImage})`,
          "--mobile-image": `url(${mobileImage})`,
        } as React.CSSProperties
      }
    >
      <div className={styles.desktopBackground}></div>
      <div className={styles.mobileImageContainer}>
        <img
          alt={altText || title}
          src={mobileImage}
          className={styles.mobileImageContent}
        />
      </div>

      <div className={styles.contentContainer}>
        <div
          className={`${styles.gridContainer} ${
            contentPosition === "right" ? styles.reverse : ""
          }`}
        >
          <div className={styles.textContent}>
            <div className={styles.title}>
              <h2>{title}</h2>
            </div>

            <div className={styles.description}>
              <p>{description}</p>
            </div>

            <div className={styles.ctaContainer}>
              {videoButtonText && (
                <button
                  className={`${styles.ctaButton} ${styles.ctaSecondary}`}
                  onClick={videoButtonOnClick}
                >
                  <span>{videoButtonText}</span>
                  <span className={styles.iconSpacing}>
                    <FaPlayCircle />
                  </span>
                </button>
              )}

              {demoButtonText && demoButtonLink && (
                <a
                  href={demoButtonLink}
                  className={`${styles.ctaButton} ${styles.ctaPrimary}`}
                >
                  <span>{demoButtonText}</span>
                  <span className={styles.iconSpacing}>
                    <FaArrowRight />
                  </span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
